<template>
  <div v-if="!loading" id="chart" style="max-height: 615px;">
    <v-row dense class="fill-height" align="stretch">
      <v-col v-for="(chart, index) in charts" :key="index" cols="3" class="d-flex flex-column">
        <apexchart type="donut" :options="chart.options" :series="chart.series" ></apexchart>
      </v-col>
    </v-row>
  </div>
  <div v-else style="min-height: 315px;">
    <v-progress-circular indeterminate color="primary"></v-progress-circular>
    Carregando dados...
  </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'

export default {
  components: {
    apexchart: VueApexCharts
  },
  props: {
    idAno: Number,
    idUnidade: Number
  },
  data() {
    return {
      loading: true,
      charts: [
        { title: '1º Bimestre', series: [], options: {} },
        { title: '2º Bimestre', series: [], options: {} },
        { title: '3º Bimestre', series: [], options: {} },
        { title: '4º Bimestre', series: [], options: {} }
      ],
      baseChartOptions: {
        chart: {
          type: 'donut',
          width: 80,
          height: 80,
        },
        labels: ['Avaliação registrada', 'Avaliação não registrada'],
        colors: ['#008C4A', '#FF4560'],
        dataLabels: {         
          style: {
            colors: ['#000000'],
            fontWeight: '400',
            fontSize: '16px'
          }
        },
        plotOptions: {
          pie: {
            dataLabels: {
              offset: 35 // ajusta a posição das labels; teste diferentes valores
            },
            donut: {
              labels: {
                show: true,
                name: {
                  show: false // se não quiser mostrar o nome da série no centro
                },
                value: {
                  show: false // se não quiser mostrar o valor total no centro
                },
                total: {
                  show: false // se não quiser mostrar o total no centro
                }
              }
            }
          }
        },
        legend: {
          position: 'bottom',
          horizontalAlign: 'center',
        },
        responsive: [{
          breakpoint: 480,
          options: {
            chart: {
              width: 200
            },
            legend: {
              position: 'bottom'
            }
          }
        }],
      }
    }
  },
  methods: {
    getBimestreData(bimestreIndex) { 
      this.axios.get('avaliacao/acompanhamentoavaliacao', {
        params: {
          intUnidade: this.idUnidade,
          intAnoLetivo: this.idAno,
          intTipoSegmento: 4,
          strDivisao: `%${bimestreIndex + 1}%`,
          intTipoRel: 1
        }
      }).then(res => {
        const data = res.data[0];
        const sim = parseInt(data.Sim, 10);
        const total = parseInt(data.Total, 10);
        this.charts[bimestreIndex].series.push(sim, (total - sim));
        this.loading = false
      })
    }
  },
  mounted() {
    this.charts.forEach((chart, index) => {
      // Assign base chart options and set the title for each bimestre
      chart.options = { ...this.baseChartOptions, title: { text: chart.title } };
      // Fetch data for each bimestre
      this.getBimestreData(index);
    });
  }
}
</script>

<style>
.apexcharts-canvas {
  width: 50% !important;
  height: 50% !important;
}
</style>