<template>
        <div class="page">
            <progress-system :isLoad="progress"></progress-system>
            <div class="container-fluid">
             <br>
                <div class="d-sm-flex align-items-center justify-content-between mb-4">
                 <h1 class="h3 mb-0 text-gray-800">Meus atendimentos</h1>
                
                </div>
                <v-row >
                    <v-col :cols="$vuetify.breakpoint.xsOnly ? '12' : dynamicCol">
                        <v-select
                            dense
                            outlined
                            clearable
                            hide-details
                            label="Filtrar pelo ano"
                            :items="datas"
                            v-model="ano"
                            item-value="id"
                            item-text="ano"
                            @change="getAtendimentos(), this.search = null"
                        ></v-select>    
                    </v-col>
                    <v-col :cols="$vuetify.breakpoint.xsOnly ? '12' : dynamicCol">
                        <v-select
                            hide-details
                            outlined
                            dense
                            clearable
                            v-model="Unidade"
                            :items="listaatendimentos"
                            item-text="Unidade"
                            item-value="Unidade"
                            label="Filtrar pela unidade"
                            @change="getAtendimentos()"
                            
                        ></v-select>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col :cols="$vuetify.breakpoint.xsOnly ? '12' : dynamicCol">
                        <v-select
                            dense
                            outlined
                            clearable
                            hide-details
                            label="Filtrar pelo procedimento"
                            v-model="Procedimento"
                            :items="listaatendimentos"
                            item-text="Procedimento"
                            item-value="Procedimento"
                            @change="getAtendimentos()"
                           
                        ></v-select>    
                    </v-col>
                    <v-col :cols="$vuetify.breakpoint.xsOnly ? '12' : dynamicCol">
                        <v-select
                            hide-details
                            outlined
                            dense
                            clearable
                            v-model="Especialidade"
                            :items="listaatendimentos"
                            item-text="Especialidade"
                            item-value="Especialidade"
                            label="Filtrar pela especialidade"
                            @change="getAtendimentos()"
                           
                        ></v-select>
                    </v-col>
                </v-row>
                <v-data-table
                    :headers="headers"
                    :items="listaatendimentos"
                    :items-per-page="10"
                    :search="search"
                    class="elevation-1"
                >
                <template v-slot:[`item.DataConsulta`]="{ item }">
                    <span>{{ item.DataConsulta ? $moment(item.DataConsulta).format('L LTS') : '-' }}</span>
                </template>
                <template v-slot:[`item.actions`]="{ item }">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon 
                                v-bind="attrs" 
                                v-on="on" 
                                icon
                                @click="getTruth(item)"

                                color="primary">
                                mdi-menu-down-outline
                            </v-icon>
                        </template>
                        <span> Informações adicionais</span>
                    </v-tooltip>
                </template>
                </v-data-table>
                    <template v-if="visible">
                    <div class="text-center">
                        <v-dialog
                        v-model="visible"
                        max-width="70%"
                        >
                        <v-card>
                            <v-card-title>
                                <span class="text-h5">Informações do atendimento</span>
                            </v-card-title>
                            <v-card-text>
                            <v-container>
                                <v-row>
                                    <v-col
                                    :key="index" v-for="index in modaltest"
                                    :cols="$vuetify.breakpoint.xsOnly ? '12' : '6'"
                                >
                                    <v-text-field
                                    readonly
                                    :hint="index[0]"
                                    v-model="index[1]"
                                    persistent-hint
                                    ></v-text-field>
                                </v-col>
                                </v-row>
                            </v-container>
                            </v-card-text>
                            <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                                color="blue darken-1"
                                dark
                                @click="visible = false"
                            >
                                FECHAR
                            </v-btn>
                            </v-card-actions>
                        </v-card>
                        </v-dialog>
                    </div>
                    </template>
            </div>    
        </div>
</template>
<script>
import ProgressSystem from '../../components/ProgressSystem'
import moment from 'moment'

export default {

    name:'Atendimentos',
    components:{
        ProgressSystem
    },
    data: () => ({
        dynamicCol: '',
        progress:true,
        Unidade: null,
        ano: null,
        Procedimento: null,
        Especialidade: null,
        listaatendimentos:[],
        headers:[
            {text:'Data da consulta',value:'DataConsulta'},
            {text:'Unidade',value:'Unidade'},
            {text:'Procedimento',value:'Procedimento'},
            {text:'Especialidade',value:'Especialidade'},
            {text:'Ação',value:'actions'}
        ],
        datas:[],
        search: null,
        visible: false,
        nome: null,
        modaltest: []
    }),

    methods:{
        getAtendimentos(){
            this.axios.get('pessoa/atendimentos', {
                params: {
                    ano: this.ano,
                    unidade: this.Unidade,
                    procedimento: this.Procedimento,
                    especialidade: this.Especialidade

                },
            }).then(res =>{
            this.listaatendimentos = res.data;
            this.listaatendimentos.sort(
                function(a,b){
                    if (a.Nome < b.Nome){return -1;}
                    if (a.Nome > b.Nome){return 1;}
                    else return 0;
                },
            )
            }).catch( e => {
                console.log(e.response);
            }).finally(()=>{
                this.progress = false;
            })
        },
        gerarAnos() {
            var max = new Date().getFullYear()
            var min = max - 7
            var anos = []

            for (var i = max; i >= min; i--) {
                anos.push({
                    id: i,
                    ano: i.toString()
                })
            }
            this.datas = anos
        },
        objectToArray(obj){
            const keys = Object.keys(obj)
            let array = ['Atendimento', 'Paciente', 'Data da consulta', 'Tempo de permanência', 'Tempo de espera', 'Data de chegada', 'Data de medicação', 'Data de saída', 'Procedimento', 'Unidade', 'Especialidade', 'Queixa do paciente', 'Nome do médico']
            this.modaltest = []

            for(let i = 0; i < keys.length; i++){
                if(obj[keys[i]] == null)
                    this.modaltest.push([array[i], 'Informação não cadastrada'])
                else if(keys[i] == "DataConsulta" || keys[i] == "DataMedicacao" || keys[i] == "DataChegada" || keys[i] == "DataSaida")
                    this.modaltest.push([array[i], this.format_date(obj[keys[i]])])
                else if(keys[i] == "NomePaciente")
                    this.nome = obj[keys[i]]
                else
                    this.modaltest.push([array[i], obj[keys[i]]])
            }
        },
        getTruth(item){
            this.objectToArray(item)
            this.visible = true
        },
        format_date(value){
            return moment(value).format('LLL')
        },
    },
    mounted(){
        this.getAtendimentos()
        this.gerarAnos()
    }
}
</script>